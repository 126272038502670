/* Estilos principais do chat */
.chat-window {
  position: fixed;
  bottom: 100px;
  right: 20px;
  width: 500px;
  height: 80vh; /* Ajuste da altura da janela para ser 80% da altura da tela */
  background-color: #fafafa; /* Cor de fundo leve para combinar com as outras cores */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Suavizei a sombra para um efeito mais leve */
  border-radius: 12px; /* Arredondei mais as bordas */
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-family: 'Roboto', sans-serif; /* Fonte mais moderna */
}

.chat-header {
  flex: 0 1 auto;
  padding: 16px; /* Aumentei o padding */
  text-align: center;
  background: linear-gradient(135deg, #4a148c, #6a1b9a); /* Fundo com gradiente roxo */
  color: white; /* Texto branco no header */
}

.chat-header h1 {
  font-size: 20px; /* Aumentei o tamanho da fonte */
  margin: 0;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-header .header-icon {
  margin-right: 10px; /* Adicionado espaço para um ícone */
}

.chat-content {
  flex: 1 1 auto;
  overflow-y: auto;
  padding: 16px; /* Aumentei o padding */
  scroll-behavior: smooth; /* Scroll suave */
  animation: fadeIn 0.5s ease-in-out; /* Animação de fade-in ao carregar */
}

.chat-footer {
  flex-shrink: 0;
  border-top: 1px solid #ddd;
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #fff;
}

.message-input {
  flex: 1;
  padding: 12px; /* Aumentei o padding */
  border: 1px solid #ccc;
  border-radius: 20px; /* Borda mais arredondada */
  font-size: 14px; /* Ajustei o tamanho da fonte */
  transition: border 0.3s ease, box-shadow 0.3s ease;
}

.message-input:focus {
  border-color: #6a1b9a;
  box-shadow: 0 0 8px rgba(106, 27, 154, 0.5);
  outline: none;
}

.send-button {
  padding: 12px 20px; /* Aumentei o padding */
  background-color: #6a1b9a;
  color: #fff;
  border: none;
  border-radius: 20px; /* Borda mais arredondada */
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.send-button:hover {
  background-color: #8e24aa;
  box-shadow: 0 4px 8px rgba(142, 36, 170, 0.3);
}

.send-button:active {
  background-color: #4a148c;
  box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.2);
}

.chat-icon-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #4a148c;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Estilos para o ChatSteps */
.message-container {
  display: flex;
  align-items: flex-end; /* Alinhei as mensagens ao fim, para ficarem melhor posicionadas */
  margin-bottom: 16px; /* Aumentei o espaçamento entre as mensagens */
  animation: fadeInUp 0.5s ease-in-out; /* Animação de fade-in e deslocamento para cima */
}

.message-container.user {
  justify-content: flex-end;
}

.icon {
  margin-right: 8px;
}

.message-container.user .icon {
  margin-right: 0;
  margin-left: 8px;
  order: 2;
}

.message-container.user .message-bubble {
  order: 1;
}

.message-bubble {
  background-color: #e1e1e1;
  padding: 12px; /* Aumentei o padding */
  border-radius: 16px; /* Borda mais arredondada */
  max-width: 70%;
  word-wrap: break-word;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Adicionei uma sombra suave */
}

.message-container.user .message-bubble {
  background-color: #d1c4e9; /* Cor suave para as mensagens do usuário */
  color: #333; /* Cor do texto */
}

.message-container.bot .message-bubble {
  background-color: #e8eaf6; /* Cor suave para as mensagens do bot */
  color: #333; /* Cor do texto */
}

/* Indicador de carregamento */
.typing-indicator {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 2px;
  background-color: #4a148c;
  border-radius: 50%;
  animation: typing 1.5s infinite;
}

.typing-indicator:nth-child(2) {
  animation-delay: 0.3s;
}

.typing-indicator:nth-child(3) {
  animation-delay: 0.6s;
}

/* Botões de interação */
.interaction-button {
  padding: 10px 15px;
  background-color: #4a148c;
  color: white;
  border: none;
  border-radius: 10px;
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.interaction-button:hover {
  background-color: #6a1b9a;
}

/* Animações */
@keyframes typing {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-6px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
